<template>
  <div>
    <b-modal
      v-model="showModal"
      :title="modalTitle"
      :header-bg-variant="variant"
      header-text-variant="default"
      hide-header-close
      centered
      no-close-on-backdrop
    >
      <b-container fluid>{{ modalContent }} </b-container>

      <template #modal-footer>
        <b-button size="md" variant="outline-primary" @click="okClicked()">
          OK
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { EventBus } from "../../router/EventBus";
import { BModal, BButton } from "bootstrap-vue";

export default {
  components: { BModal, BButton },

  data() {
    return {
      modalTitle: "",
      modalContent: "",
      showModal: false,
      variant: "info",
      emitOnOk: false,
    };
  },

  mounted() {
    EventBus.$on("showCommonModal", (modalValues) => {
      this.modalTitle = modalValues.title;
      this.modalContent = modalValues.content;
      this.variant = modalValues.variant ? modalValues.variant : this.variant;
      this.emitOnOk = modalValues.isEmit;
      this.showModal = true;
    });
  },

  methods: {
    okClicked() {
      this.showModal = false;
      if (this.emitOnOk) {
        EventBus.$emit("modalOkClicked");
      }
      this.modalTitle = "";
      this.modalContent = "";
      this.showModal = false;
      this.variant = "info";
      this.emitOnOk = false;
    },
  },
};
</script>
